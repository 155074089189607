const data = {
    newContent: {
        smallTitle:'News',
        title:"CUCo Security Suite successfully launched at GESS Dubai 2024",
        description:"Learn about our experience at GESS Dubai 2024, where we officially launched CUCo Security Suite and highlighted its utility in the world of education.",
        date:"2024.11.28",
        image: {
            src:"./images/news_gess.png",
            alt:"news image",
        },
        text: [
            {
                paragraph: "This November, at GESS Dubai 2024, CUCo Security Suite was officially launched. CUCo Security Suite is an innovative solution designed to redefine endpoint security and device management in the education sector. Recognized as the leading educational conference in the EMEA region, GESS Dubai attracted thousands of industry professionals, making it the ideal opportunity to present this cutting-edge technology.",
                bold: false
            },
            {
                paragraph: "CUCo Security Suite was presented as a firmware-based solution, focusing on three main areas of actuation: endpoint security, device management and data protection, integrating three complementary products: CUCo Remote Lock - a robust security module designed to enhance device protection and prevent unauthorized access; CUCo Secure Endpoint - a thorough device management system developed to optimize and secure remote management and monitoring tasks; CUCo Remote BIOS Management - an innovative BIOS management solution constructed with the goal of making BIOS configuration and security accessible at every level.",
                bold: false
            },
            {
                paragraph: "During this event, we highlighted how CUCo Security Suite can support the education sector, from the planning and implementation of device distribution projects to the maintenance and continuous management of said projects. Furthermore, we demonstrated how this solution is essential for the protection of students and administrations, contributing to a safer and more efficient learning environment.",
                bold: false
            },
            {
                paragraph: "After this successful experience at GESS Dubai, we are excited to, once more, bring CUCo Security Suite to the global stage, this time at Bett UK 2025, taking place in London, from the 22nd to the 24th of January. This is another important event in the education sector, where we hope to continue to present innovative solutions.",
                bold: false
            },
        ]
    }, 
    button: {
        text: "All articles",
        page: "/news"
    }   
}


export default data;
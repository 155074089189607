const data = {
    categories: [
        /* {
            name: "Education",
        },
        {
            name: "Corporate",
        },
        {
            name: "Government",
        },
        {
            name: "Education",
        },
        {
            name: "Corporate",
        },
        {
            name: "Government",
        },

        {
            name: "Education",
        },
        {
            name: "Corporate",
        },
        {
            name: "Government",
        }, */    
    ],
    items: [
        {
            category:'2024-11-28',
            image: {
                src: "./images/news_gess.png",
                alt: "image"
            },
            title: 'CUCo Security Suite successfully launched at GESS Dubai 2024',
            description: 'Learn about our experience at GESS Dubai 2024, where we officially launched CUCo Security Suite and highlighted its utility in the world of education.',
            page:"/news/gess"
        },
        {
            category:'2024-10-30',
            image: {
                src: "./images/thumbnail_bett.png",
                alt: "image"
            },
            title: 'CUCo Security Suite at Bett UK 2025',
            description: 'CUCo Security Suite showcased at Bett UK 2025 from the 22nd to the 24th of January.',
            page:'/news/bett'
        },

    ]

}

export default data;
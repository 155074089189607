const data = {
    info: {
        number:'+3 million',
        text: 'devices worldwide',
        image: {
            src: '',
            alt: ''
        }
    },    
    slider: [
       {
        title: 'Firmware based secure endpoint',
        buttons: [
            {
                text: '',
                page: ''
            },
            {
                text: 'Learn More',
                page: '/remote-lock'
            }
        ],
        description:"Integrating security and remote management features, CUCo Security Suite is the firmware-based solution capable of answering all your projects' safety needs",            
        backgroundImage: {
            src:'./images/banner-background.png',
            alt:'banner image'
        },
   },
   {
    title: 'CUCo Security Suite successfully launched at GESS Dubai 2024',
    buttons: [
        {
            text: '',
            page: ''
        },
        {
            text: 'Learn More',
            page: '/news/gess'
        }
    ],
    description:"Learn about our experience at GESS Dubai 2024, where we officially launched CUCo Security Suite and highlighted its utility in the world of education.",            
    backgroundImage: {
        src:'./images/banner_gess.png',
        alt:'banner image'
    },
},
{
    title: 'CUCo Security Suite at Bett UK 2025',
    buttons: [
        {
            text: '',
            page: ''
        },
        {
            text: 'Learn More',
            page: '/news/bett'
        }
    ],
    description:"CUCo Security Suite showcased at Bett UK 2025 from the 22nd to the 24th of January.",            
    backgroundImage: {
        src:'./images/banner2.png',
        alt:'banner image'
    },
},
    ]    
}

export default data;
const data = {
    title: 'Features checklist',
    leftItems: [
        {
            text: 'Remote software management tools, allowing for monitoring, removal or installing software in multiple devices at the same time'
        },
        {
            text: 'User data wipe for authorized devices'
        },
        {
            text: 'Prevents the installation of unauthorized software'
        },
        {
            text: 'Safe Browsing program, including parental control and content management functionalities'
        },
        {
            text: 'DNS filtering, granting protection against major DNS-based threats'
        },
        {
            text: 'Malware and Spyware detection and prevention'
        },
        {
            text: 'Reports and alerts based on specified protocols'
        },
        {
            text: 'Immediate alerts upon connection of a missing device to the Internet'
        },
        {
            text: 'Access to hardware and software inventory'
        },
    ],
    rightItems: [
       
        {
            text: 'Audits management tasks performed'
        },
        {
            text: 'Enforces compliance with licensing agreements'
        },
        {
            text: 'Hardware performance monitoring, including alerts when the device exceeds RAM and CPU usage, along with storage capacity thresholds'
        },
        {
            text: 'Access to device location history '
        },
        {
            text: 'Geofencing tools enforcing pre-determined procedures'
        },
        {
            text: 'Real-time tracking of device location'
        },
        {
            text: 'Identification and registration of devices reported as missing'
        },
        {
            text: 'Tracking of login and unlock events'
        }
    ]
}

export default data;